import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppNav } from "@models/shared/navigation.models";
import { fullNav } from "@helpers/constants/menu.constants";
import { AccountService } from "@services/account/account.service";

@Injectable({ providedIn: 'root' })
export class NavigationService {

    get navigation$(): Observable<AppNav> {
        return this.get();
    }

    get(): Observable<AppNav> {
        let mainList = fullNav;

        let paymentAllowed = AccountService.isInRole('payment.all');
        if (!paymentAllowed) {
            mainList = mainList.filter(x => x.id != "payBill");
        }

        mainList = mainList.map(x => {
            x.tooltip = x.title;
            return x;
        })

        let returnModel = {
            horizontal: mainList,
            compact: mainList,
            default: mainList,
            futuristic: mainList
        } as AppNav;

        return of(returnModel);
    }
}
