import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { tap } from 'rxjs';
import { inject } from '@angular/core';
import { AppErrorService } from '@services/infrastructure/app-error.service';

export function errorInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
    let reqHeaders = request.headers;
    const errorService = inject(AppErrorService);
    if (!reqHeaders.has('warning-allowed'))
        reqHeaders = reqHeaders.append('warning-allowed', '1');

    let newReq = request.clone({
        headers: reqHeaders
    });

    return next(newReq).pipe(
        tap(
            () => {
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (!err.url.startsWith("https://graph.microsoft.com/v1.0/me/photos/64x64/$value"))
                        errorService.handleError(err, true);
                }
            })
    );
}