import { FuseNavigationItem } from '@fuse/components/navigation';

export const fullNav: FuseNavigationItem[] = [
    {
        id: 'documents',
        title: 'Documents',
        subtitle: 'Patient & Visit Documents',
        type: 'basic',
        icon: 'heroicons_solid:clipboard-document-list',
        link: '/secure-area/documents'
    },
    {
        id: 'payBill',
        title: 'Pay Bill',
        subtitle: 'Make a new payment',
        type: 'basic',
        icon: 'heroicons_solid:credit-card',
        link: '/secure-area/payBill'
    },
    {
        id: 'statements',
        title: 'Statements',
        subtitle: 'Recent Statements',
        type: 'basic',
        icon: 'heroicons_solid:document-check',
        link: '/secure-area/statements'
    },
    {
        id: 'previousPayments',
        title: 'Previous Payments',
        subtitle: 'Recent Payments',
        type: 'basic',
        icon: 'heroicons_solid:banknotes',
        link: '/secure-area/previousPayments'
    },
    {
        id: 'contactUs',
        title: 'Contact Us',
        subtitle: 'Get in touch',
        type: 'basic',
        icon: 'heroicons_outline:phone',
        link: '/secure-area/contact-us'
    },
    {
        id: 'legal',
        title: 'Legal',
        subtitle: 'Legal Disclaimer',
        type: 'basic',
        icon: 'heroicons_outline:document-chart-bar',
        link: '/secure-area/legal'
    }
]