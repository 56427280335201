import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { HealthCheckComponent } from "@modules/open-area/health-check/health-check.component";
import { noAuthGuard } from "@helpers/guards/noAuth.guard";
import { userAuthGuard } from "@helpers/guards/auth.guard";
import { initialDataResolver } from "./app.resolvers";

export const appRoutes: Route[] = [
    {
        path: 'ec_healthcheck',
        pathMatch: 'full',
        component: HealthCheckComponent
    },
    {
        path: 'open-area',
        pathMatch: 'prefix',
        component: LayoutComponent,
        canActivate: [ noAuthGuard ],
        canActivateChild: [ noAuthGuard ],
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('@modules/open-area/open-area.routes') }
        ]
    },
    {
        path: 'secure-area',
        pathMatch: 'prefix',
        canActivate: [ userAuthGuard ],
        canActivateChild: [ userAuthGuard ],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: '', loadChildren: () => import('@modules/secure-area/secure-area.routes') }
        ]
    },
    {
        path: '**',
        redirectTo: 'secure-area'
    }
];