import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, ReplaySubject } from 'rxjs';
import { User } from "@models/shared/navigation.models";
import { environment } from "@env/environment";

@Injectable({ providedIn: 'root' })
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this.getUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User> {
        return this.getUser();
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    getUser() {
        const user = {
            id: environment.userInfo.id,
            name: environment.userInfo.displayName,
            email: environment.userInfo.userPrincipalName,
            avatar: environment.userInfo.img,
            status: 'online',
            title: environment.userInfo.jobTitle
        };

        // if (!user.avatar)
        //     user.avatar = "assets/images/avatars/noUser.png";

        return of(user);
    }
}
