import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { AccountService } from "@services/account/account.service";

export const noAuthGuard: CanActivateChildFn =
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const router = inject(Router);
        let isAuthenticated = AccountService.isAuthenticated();
        if (!isAuthenticated)
            return true;

        return router.navigate([ '/secure-area' ]);
    };