import { Component } from '@angular/core';
import { environment } from "@env/environment";

@Component({
    selector: '[app-footer]',
    standalone: true,
    imports: [],
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    year = new Date().getFullYear();
    appInfo = environment.appInfo;
}
