import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import { UrlConfigModel } from "@models/shared/shared-models";
import { AccountService } from "@services/account/account.service";

export function urlMagicHttpInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
    let urlConfig = getUrlConfig(request.url);
    if (!urlConfig)
        return next(request);

    let newUrl = substituteUrl(urlConfig, request.url);
    let accessToken = getToken(urlConfig);

    request = request.clone({
        url: newUrl,
        headers:
            !accessToken ? undefined : request.headers.set('Authorization', `Bearer ${accessToken}`)
    });

    return next(request);

    function getUrlConfig(url: string) {
        if (!url)
            return undefined;

        if (!url.startsWith('@'))
            return undefined;

        let alias = url;
        let urlIndex = url.indexOf('/');
        if (urlIndex !== -1)
            alias = url.substring(0, urlIndex);

        return environment.urlStore.find(value => value.alias === alias);
    }

    function getToken(record: UrlConfigModel) {
        if (record.scopes.includes("patient_care_jwt")) {
            let userModel = AccountService.getUser();
            return userModel?.tokenInfo?.accessToken ?? "";
        }

        return null;
    }

    function substituteUrl(record: UrlConfigModel, url: string) {
        if (!record.overrideUrl)
            return url;

        let aliasReplacement = record.overrideUrl;
        if (record.relativeUrl)
            aliasReplacement = aliasReplacement.combineUrl(record.relativeUrl);

        return url.replace(record.alias, aliasReplacement);
    }
}