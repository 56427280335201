import { APP_INITIALIZER, ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider, } from '@angular/core';
import { FuseIconsService } from '@services/layout/fuse-icons.service';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { dateInterceptor } from "@helpers/interceptors/angular-date.interceptor";
import { errorInterceptor } from "@helpers/interceptors/error.interceptor";
import { urlMagicHttpInterceptor } from "@helpers/interceptors/url-magic-http.interceptor";
import { AppInitService, initializeApp } from "@services/infrastructure/app-init.service";

export const getAppInitializer = () => {

    return {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        multi: true,
        deps: [ AppInitService ]
    };
};

export const getHttpAndInterceptorProviders = (): Array<Provider | EnvironmentProviders> => {

    let interceptors =
        [
            //changes url in http client
            urlMagicHttpInterceptor,

            //shows notification on error
            errorInterceptor,

            //normalizes dates in response
            dateInterceptor
        ];
    return [
        provideHttpClient(withInterceptors(interceptors), withInterceptorsFromDi())
    ];
};

export const getIconProviders = (): Array<Provider | EnvironmentProviders> => {
    return [
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(FuseIconsService),
            multi: true,
        },
    ];
};
