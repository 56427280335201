import { forkJoin, of } from 'rxjs';
import { environment } from "@env/environment";
import { AccountService } from "@services/account/account.service";
import { EventService } from "@services/infrastructure/event.service";
import { inject } from "@angular/core";

export const initialDataResolver = () => {
    let eventService = inject(EventService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        setupUser(),
    ]);

    function setupUser() {
        let userInfo = AccountService.getUser();
        let userId = userInfo?.patientInfo?.patientId?.toString() ?? "";
        if (environment.userInfo.id !== userId) {
            environment.userInfo = {
                displayName: userInfo.patientInfo.displayName,
                mail: userInfo.patientInfo.email,
                jobTitle: userInfo.patientInfo.patientAccountNumber,
                id: userInfo.patientInfo.patientId.toString()
            };

            eventService.broadcast("user.update", userInfo);
        }

        return of(true);
    }
};