import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppInfoModel, GetServiceResponsesModel, UrlConfigModel } from '@models/shared/shared-models';
import { lastValueFrom, mergeMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { appRoutes } from '../../../app.routes';
import { RouteDictionaryService } from '@services/infrastructure/route-dictionary.service';
import { TenantInfoService } from "@services/account/tenant-info.service";
import { Router } from "@angular/router";

export function initializeApp(appInitService: AppInitService): () => Promise<any> {
    return () => appInitService.initializeApp();
}

@Injectable({
    providedIn: 'root'
})

export class AppInitService {

    constructor(private httpClient: HttpClient,
                private routeService: RouteDictionaryService,
                private tenantService: TenantInfoService,
                private router: Router) {

    }

    initializeApp(): Promise<any> {
        const salt = (new Date()).getTime();
        let url = `/assets/appInfo.json?${salt}`;

        return lastValueFrom(
            this.httpClient
                .get<AppInfoModel>(url)
                .pipe(mergeMap(x => {
                    environment.appInfo = x;
                    return this.routeService.generateRouteDictionary(appRoutes);
                }))
                .pipe(mergeMap(x => {
                    environment.routeDictionary = x;
                    return this.setupUrl(environment.appInfo,
                        environment.urlStore);
                }))
                .pipe(mergeMap(() => {
                    return this.tenantService.getTenants();
                }))
                .pipe(tap(x => {
                    environment.activeTenants = x;
                    this.captureTenant();
                }))
        );
    }

    private setupUrl(appInfo: AppInfoModel, urlModels: UrlConfigModel[]) {
        let locatorUrl =
            environment.serviceLocatorUrl.combineUrl('/getServices');

        let reqModels = urlModels.map(x => {
            return {
                namespace: x.artifactName,
                projectBranch: appInfo.buildSourceBranch
            };
        });

        return this.httpClient
            .post<GetServiceResponsesModel>(locatorUrl, {
                requests: reqModels
            })
            .pipe(map(response => {
                environment.urlStore.forEach(value => {
                    let serverResp =
                        response.responses.find(x => x.namespace == value.artifactName);

                    if (!value.overrideUrl) {
                        if (value.useLocal)
                            value.overrideUrl = serverResp.localEndpoint;
                        else if (serverResp.featureEndpoint)
                            value.overrideUrl = serverResp.featureEndpoint;
                        else
                            value.overrideUrl = serverResp.serviceEndpoint;
                    }

                    let scopeList = [];
                    if (value.scopes && value.scopes.length) {
                        value.scopes.forEach(scope => {
                            scope = scope.replace("{env}", environment.name);
                            scopeList.push(scope);
                        })
                    }

                    value.scopes = scopeList;
                });

                return true;
            }));
    }

    private captureTenant() {
        let currentUrl = document.documentURI.replace(document.baseURI, '');
        const parsedUrl = parseUrl(currentUrl);
        let refCodeVal = parsedUrl.queryParams['rc'];
        let currentUrlArr = parsedUrl.route.split('/');

        //If client came with referral code, capture it
        if (refCodeVal) {
            let metaData = TenantInfoService.getTenantMetaData();
            metaData.tenantReferralCode = refCodeVal;
            this.tenantService.saveTenantMetaData(metaData);
        }

        //if client came with tenant name, capture it
        if (currentUrlArr.length >= 1) {
            let tenantName = currentUrlArr[0];
            tenantName = tenantName.toLowerCase();
            let validTenant = environment.activeTenants.includes(tenantName);
            if (validTenant) {
                //Save in local storage
                let metaData = TenantInfoService.getTenantMetaData();
                metaData.tenantIdentifier = tenantName;
                this.tenantService.saveTenantMetaData(metaData);

                //Remove tenant name from url and carry on
                currentUrlArr.splice(0, 1);
                let newUrl = currentUrlArr.join('/');
                if (parsedUrl.queryString)
                    newUrl += `?${parsedUrl.queryString}`;

                this.router.navigateByUrl(newUrl).then();
            }
        }

        function parseUrl(url: string): {
            route: string; queryString: string,
            queryParams: Record<string, string>
        } {
            // Split the URL into the route and the query string parts
            const [ route, queryString ] = url.split('?');

            // Initialize an empty object to hold the query parameters
            const queryParams: Record<string, string> = {};

            // Check if there's a query string
            if (queryString) {
                // Split the query string into key-value pairs
                const pairs = queryString.split('&');

                // Iterate over the key-value pairs and add them to the queryParams object
                for (const pair of pairs) {
                    const [ key, value ] = pair.split('=');
                    queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
                }
            }

            // Return the route and the query parameters
            return { route, queryString, queryParams };
        }
    }
}