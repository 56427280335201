import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridColumnTemplateComponent } from '@shared/components/grid-column-template.component';
import { ComboItemTemplateComponent } from '@shared/components/combo-item-template.component';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CustomDialogService } from '@services/infrastructure/custom-dialog.service';
import { EventService } from "@services/infrastructure/event.service";
import { Subject, takeUntil } from "rxjs";
import { AccountService } from "@services/account/account.service";
import { TenantInfoService } from "@services/account/tenant-info.service";
import { mergeMap } from "rxjs/operators";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    standalone: true,
    imports: [
        RouterOutlet,
        DialogsModule,
        GridColumnTemplateComponent,
        ComboItemTemplateComponent
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    formData = {
        destroy$: new Subject(),
        idleTimer: null as any
    }

    constructor(private router: Router,
                private dialogService: CustomDialogService,
                private eventService: EventService,
                private userService: AccountService,
                private tenantInfoService: TenantInfoService) {
    }

    ngOnInit(): void {
        this.router
            .events
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe((event: any) => {
                if (event instanceof NavigationStart) {
                    this.dialogService.closeAll();
                }
            });

        //On page load
        this.setupIdleCheck();

        //On user update
        this.eventService
            .listen('user.update')
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe(() => {
                this.setupIdleCheck();
            });

        //Set on page load
        this.setupAnalytics();

        //Update on tenant meta data update
        this.eventService
            .listen('userMetaData.update')
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe(() => {
                this.setupAnalytics();
            });
    }

    ngOnDestroy() {
        this.formData.destroy$.next(null);
        this.formData.destroy$.complete();
    }

    private setupIdleCheck() {
        let that = this;
        let needsIdleCheck = AccountService.isAuthenticated();
        if (needsIdleCheck && !this.formData.idleTimer) {
            this.formData.idleTimer = setInterval(checkLogin, 30000);
        } else if (!needsIdleCheck && this.formData.idleTimer !== null) {
            clearInterval(this.formData.idleTimer);
            this.formData.idleTimer = null;
        }

        function checkLogin() {
            that.userService
                .getUserInfo()
                .pipe(takeUntil(that.formData.destroy$))
                .subscribe({
                    error: () => {
                        that.userService.saveUser();
                        that.router.navigate([ '/open-area/sign-in' ]).then();
                    }
                });
        }
    }

    private setupAnalytics() {
        let tenantInfoModel = TenantInfoService.getTenantMetaData();
        let referralCode = tenantInfoModel?.tenantReferralCode ?? "";
        this.tenantInfoService
            .getTenantInfo()
            .pipe(takeUntil(this.formData.destroy$))
            .pipe(mergeMap(x => this.tenantInfoService.getTenantAnalytics(x?.identifier, referralCode)))
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe(scripts => {
                scripts.forEach(script => {
                    setupScripts(script);
                });
            });

        function setupScripts(scriptBlock: string) {
            const domParser = new DOMParser();
            const htmlElement = domParser.parseFromString(scriptBlock, 'text/html');
            let scriptElements = htmlElement.getElementsByTagName("script");
            if (scriptElements.length) {
                let scriptElement = scriptElements[0];
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.innerHTML = scriptElement.innerHTML;
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        }
    }
}