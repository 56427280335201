import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { ElementReferenceService } from '@services/infrastructure/element-reference.service';

@Component({
    selector: 'combo-item-template',
    standalone: true,
    imports: [
        NgForOf,
        ComboBoxModule,
        NgIf
    ],
    template: `
        <ng-template #itemTemplate kendoComboBoxItemTemplate let-dataItem>
            <ng-template [ngForOf]="dataItem.templateDataParsed" let-item ngFor>
                <ng-container *ngIf="!item.key.startsWith('_')">
                    {{ item.key }}: {{ item.value }}
                </ng-container>
                <ng-container *ngIf="item.key.startsWith('_')">
                    {{ item.value }}
                </ng-container>
                <br/>
            </ng-template>
        </ng-template>`
})
export class ComboItemTemplateComponent implements AfterViewInit {

    @ViewChild('itemTemplate') itemTemplate!: TemplateRef<any>;

    constructor(private elementReferenceService: ElementReferenceService) {
    }

    ngAfterViewInit() {
        this.elementReferenceService
            .setItemTemplate('comboTemplate', this.itemTemplate);
    }
}
